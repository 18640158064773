
import { apiGetAgents, apiGetAgent } from "@/services/agents";
import decorateTranslateText from "@/mixins/decorateTranslateText";

export default {
    mixins: [decorateTranslateText],
    data() {
        return {
            swiperOptions: {
                navigation: {
                    nextEl: ".swiper-expert-button-next",
                    prevEl: ".swiper-expert-button-prev",
                },
                centeredSlides: true,
                grabCursor: true,
                effect: "coverflow",
                coverflowEffect: {
                    rotate: 0,
                    stretch: 80,
                    depth: 200,
                    modifier: 1,
                    slideShadows: true,
                },
                breakpoints: {
                    1440: {
                        slidesPerView: 3.1,
                        loop: true,
                    },
                    768: {
                        slidesPerView: 2.16,
                        loop: true,
                    },
                    0: {
                        slidesPerView: "auto",
                        loop: false,
                    },
                },
            },
            sliderData: null,
        };
    },
    async mounted() {
        this.agent = await apiGetAgent();
        this.sliderData = await apiGetAgents();
        this.sliderData?.sort((agent) =>
            agent?.id === this?.agent?.id ? -1 : 1
        );
    },
    methods: {
        requireSrc(img) {
            return require(`~/assets/images/home/services/${img}`);
        },
    },
};
